<template>
  <div class="card-highlight-v2" @click="goToLink">
    <div class="card-highlight-container">
      <div :class="['card-highlight-v2__img', {'overlay-active' : gradient}]">
        <pg-image :lazy-src="img"></pg-image>
      </div>
      <div class="card-highlight-v2__content">
        <h2 v-if="text" class="card-highlight__text">{{ text }}</h2>
        <a v-if="cta" :href="href" :target="targetBlank === true ? '_blank' : '_self'">
          <h3 class="text--heading-s text--primary card-highlight__cta">{{ cta }}</h3>
          <i aria-hidden="true" class="icon-arrow-dx"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import PgButton from "../Button/Button.vue";
import PgImage from "../Image/Image.vue";

export default {
  name: "CardHighlightV2",
  components: {
    PgButton,
    PgImage,
  },
  props: {
    href: {
      type: String,
      default: ""
    },
    img: {
      type: String,
      default: ""
    },
    text: {
      type: String,
      default: ""
    },
    cta: {
      type: String,
      default: ""
    },
    targetBlank: {
      type: Boolean,
      default: false
    },
    gradient: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    goToLink() {
      window.location.href = this.href;
    }
  },
};
</script>
